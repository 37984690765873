/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */
//ab font awesome-free
//require('@fortawesome/fontawesome-free/js/all.min.js');

// any CSS you require will output into a single css file (app.css in this case)
require('../css/app.css');
require('../css/global.scss');
require('../css/prenavbar.css');
require('../css/navbar.css');
require('../css/pageslider.css');
require('../css/titleandbreadcrumb.css');
require('../css/imgandtext.css');
require('../css/team.css');
require('../css/titleandsubtitlechecklist.css');
require('../css/iconsubtitletext.css');

// Home Page
require('../css/slider.css');
require('../css/whowhatwhy.css');
require('../css/ibninsights.css');
require('../css/questionsbanner.css');
require('../css/ibnnews.css');
require('../css/values.css');
require('../css/engage.css');

// Our Company
require('../css/ourcompany.css');

// IBN Team
require('../css/ibnteam.css');

// Contact
require('../css/contact.css');

require('../css/footer.css');
require('../css/copyright.css');

// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
// ab
const $ = require('jquery');
require('bootstrap');

// ===== Scroll to Top ====
$(window).scroll(function() {
    if ($(this).scrollTop() >= 50) {        // If page is scrolled more than 50px
        $('#return-to-top').fadeIn(200);    // Fade in the arrow
    } else {
        $('#return-to-top').fadeOut(200);   // Else fade out the arrow
    }
});
$('#return-to-top').click(function() {      // When arrow is clicked
    $('body,html').animate({
        scrollTop : 0                       // Scroll to top of body
    }, 500);
});

console.log('Hello Webpack Encore! Edit me in assets/js/app.js');
